/* eslint-disable prettier/prettier */
import React from "react"
import { Link as NavLink, useLocation, useParams } from "react-router-dom";
//import Logo from "../../../assets/images/LogoBorder.svg"
import Logo from "../../../assets/images/logo-colored.svg"
import logoOntario from "../../../assets/images/logo_ontario.png"
import nttLogo from "../../../assets/images/NTT-logo.png"
import classNames from "classnames"
import { useMenu } from "../../../hooks/useMenu";
import { useCanParams } from "../../../hooks/useCanParams";

export function HeaderMain() {
  const {type,state,provider} = useCanParams();
  const menu = useMenu(state, type,provider);
  const {pathname:Routeparam} = useLocation();
  

  return (
    <header className="header">
      <nav className={classNames("navbar", "navbar-expand-lg p-0")}>
        <div className="container">
        <NavLink to='/'>
         <Logo />
        </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            </button>
          <div
            className={classNames("collapse", "navbar-collapse")}
            id="navbarSupportedContent"
          >
            <ul className={classNames('navbar-nav' ,'ms-auto' ,'mb-2' ,'mb-lg-0')}>
              {menu && <li className="nav-item dropdown" id="casinos">
                  <NavLink to="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                     aria-expanded="false">
                    {menu.label}
                  </NavLink>
                  <ul className="dropdown-menu">
                    {menu.children.map((item: any,index: React.Key | null | undefined)=>{
                      return (
                        <li key={index}>
                          <NavLink
                            className="dropdown-item" 
                            target="_blank"
                            to={item.url}>
                            {item.text}
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              }
                    <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to={`/responsible-gambilng?ba=${provider}`}>
                    Responsible Gaming
                    </NavLink>
                    </li>
                    {/* <li className="nav-item">
                     <NavLink className="navbar-brand" to="#"> <img alt="Ontario Logo" width="150px"  src={logoOntario} />
                     </NavLink>
                   </li> */}
                   { provider === "ntt" && (
                    <li className="nav-item">
                    <NavLink className="navbar-brand" to="#"> <img alt="NTT Logo" width="90px"  src={nttLogo} />
                    </NavLink>
                  </li> 
                   )}    
{/* 
                  { (Routeparam == "/responsible-gambilng" || (provider && provider !== 'playcan') )  
                     && (
                      <li className="nav-item">
                      <NavLink className="nav-link" aria-current="page" to='/survey'>
                      Survey
                      </NavLink>
                    </li>
                     ) 
                  } */}
                  
                    
                </ul>

            </div>
        </div>
    </nav>
</header>
  )
}
